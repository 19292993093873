@import './static/fonts/style.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI Bold', sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sep-right {
    border-right: 1px solid #2f2f2f;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?47927837#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: inherit;
    font-size: inherit;

    /* you can be more comfortable with increased icons size */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-icon_close:before {
    content: '\e800';
} /* '' */
.icon-icon_down:before {
    content: '\e801';
} /* '' */
.icon-icon_download:before {
    content: '\e802';
} /* '' */
.icon-icon_eye:before {
    content: '\e803';
} /* '' */
.icon-icon_feedback:before {
    content: '\e804';
} /* '' */
.icon-icon_left:before {
    content: '\e805';
} /* '' */
.icon-icon_login:before {
    content: '\e806';
} /* '' */
.icon-icon_logout:before {
    content: '\e807';
} /* '' */
.icon-icon_magnify:before {
    content: '\e808';
} /* '' */
.icon-icon_menu:before {
    content: '\e809';
} /* '' */
.icon-icon_neutral:before {
    content: '\e80a';
} /* '' */
.icon-icon_plus:before {
    content: '\e80b';
} /* '' */
.icon-icon_right:before {
    content: '\e80c';
} /* '' */
.icon-icon_portfolio:before {
    content: '\e80d';
} /* '' */
.icon-icon_search:before {
    content: '\e80e';
} /* '' */
.icon-icon_settings:before {
    content: '\e80f';
} /* '' */
.icon-icon_tablesettings:before {
    content: '\e810';
} /* '' */
.icon-icon_up:before {
    content: '\e811';
} /* '' */
.icon-icon_upload:before {
    content: '\e812';
} /* '' */
.icon-icon_user:before {
    content: '\e813';
} /* '' */
.icon-icon_dashboard:before {
    content: url(./static/icons/icon_dashboard-20px.svg);
}
.icon-icon_target-audience:before {
    content: url(./static/icons/icon_target_audience-20px.svg);
}
.icon-icon_variables:before {
    content: url(./static/icons/icon_variables-20px.svg);
}
.icon-icon_user2:before {
    content: url(./static/icons/icon_users-20px.svg);
}
.icon-icon_reports:before {
    content: url(./static/icons/icon_reports-20px.svg);
}
.icon-icon_developer:before {
    content: url(./static/icons/icon_developer.svg);
}
.icon-icon_trigger:before {
    content: url(./static/icons/icon_trigger-20px.svg);
}
.icon-icon_dashboard-active:before {
    content: url(./static/icons/active/icon_dashboard-20px.svg);
}
.icon-icon_target-audience-active:before {
    content: url(./static/icons/active/icon_target_audience-20px.svg);
}
.icon-icon_variables-active:before {
    content: url(./static/icons/active/icon_variables-20px.svg);
}
.icon-icon_user2-active:before {
    content: url(./static/icons/active/icon_users-20px.svg);
}
.icon-icon_reports-active:before {
    content: url(./static/icons/active/icon_reports-20px.svg);
}
.icon-icon_developer-active:before {
    content: url(./static/icons/active/icon_developer.svg);
}
.icon-icon_trigger-active:before {
    content: url(./static/icons/active/icon_trigger-20px.svg);
}
