@-ms-viewport {
    width: device-width;
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-size: 16px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.text-center {
    text-align: center;
}

.double-padding {
    padding-left: 90px !important;
    padding-right: 26px !important;
}

.Toastify__close-button {
    opacity: 1;
    color: #304fff !important;
    font-size: 1rem;
}
.Toastify__close-button:hover {
    color: #23338d !important;
}

.Toastify__toast {
    padding: 1.5rem;
}

.App {
    float: left;
    width: 100%;
    overflow: hidden;
    min-height: 0;
}

.img-responsive {
    max-width: 100%;
}

.text-uppercase {
    text-transform: uppercase;
}
