/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('Segoe UI.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('Segoe UI Italic.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('Segoe UI Bold.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('Segoe UI Bold Italic.woff') format('woff');
}

@font-face {
    font-family: 'fontello';
    src: url('fontello.eot');
    src: url('fontello.eot') format('embedded-opentype'), url('fontello.woff2') format('woff2'),
        url('fontello.woff') format('woff'), url('fontello.ttf') format('truetype'),
        url('fontello.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
